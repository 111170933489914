import React, {useEffect, memo, useState, useRef} from 'react'
import { Link, useParams, Outlet } from 'react-router-dom'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import StreetLeafMap from '../../components/map/StreetLeafMap'
import List from '../../components/list/List'
import InfoModal from '../../components/modal/InfoModal'
import Notebook from '../../components/notebook/Notebook'
import ProcessNotiModal from '../../components/modal/ProcessNotiModal'
import EdiDocModal from '../../components/modal/EditDocModal'
import EditTaxiModal from '../../components/modal/EditTaxiModal'
import EditBursaryModal from '../../components/modal/EditBursaryModal'
import EditMedicalModal from '../../components/modal/EditMedicalModal'
import { messageResources } from '../../assets/util/messageResources'
import Messages from '../../components/messages/Messages'
import "./studentProfile.css"
import { useSelector } from 'react-redux';
import NokCard from '../../components/card/NokCard'
import MonoDataCard from '../../components/card/MonoDataCard'
import PdStudentCard from '../../components/card/PdStudentCard'
import SupportCard from '../../components/card/SupportCard'
import EditNokModal from '../../components/modal/EditNokModal'
import TaxiCard from '../../components/card/TaxiCard'
import AssessmentCardMaths from '../../components/card/AssessmentCardMaths'
import AssessmentCardEng from '../../components/card/AssessmentCardEng'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Subjects from '../programme/Subjects'
import {isFirstHalfOfYear, currentAcademicYear, previousAcademicYear} from '../../assets/util/academiYear'
import EditSupportModal from '../../components/modal/EditSupportModal'
import ProgrammeCard from '../../components/card/ProgrammeCard'
import ProgrammeCardSecond from '../../components/card/ProgrammeCardSecond'
import ProgrammeCardThird from '../../components/card/ProgrammeCardThird'
import ProgrammeCardFourth from '../../components/card/ProgrammeCardFourth'
import AboutStudentCard from '../../components/card/AboutStudentCard'
import { useGetStudentsArchivedQuery, useGetStudentByIdQuery, selectStudentById } from '../../redux/student/studentSlice';
import { selectAllNotifications } from '../../redux/notifications/notificationsApiCalls'
import useAuth from '../../hooks/useAuth'
import EditCareersModal from '../../components/modal/EditCareersModal'

const printListComa = (list) => {

	
    var toPrint = "";
    list.map((item) => {
        toPrint += `${item.label}, `
        //console.log(toPrint)
    })   

    if(toPrint.trim().endsWith(',')) { return toPrint.slice(0, -2)} else { return toPrint }
                        
}


const StudentProfile = () => {

	//console.log("|----> StudentProfile COMPONENT StudentProfile <-------|")
	
	const columnsForStuDocuments = [
        { title: "Type",  field: "type" }, 
        { title: "Google Link",  render:rowData=> <a target="_blank" href={rowData.url_link}> CLICK TO OPEN </a> }, 
        { title: "Upload Date", field: "createdAt", type: 'date', dateSetting: { format: 'dd MMMM yyyy'} },
        { title: "Description",  field: "description" }, 
        { title: "Actions",  render:rowData=>   
                                                studentInfo?.isActive && !roles?.includes(6) && 
													<> 
														<InfoModal  
																	buttonText={messageResources.BUTTON.DELETE}
																	stuId={studentId}
																	toDeleteId={rowData.idDoc}
																	methodType="deleteDoc"
																	header={messageResources.MODAL.DEL_DOC} 
																	text={messageResources.MODAL.DEL_DOC_TEXT}
																	toastText={messageResources.TOASTY.DOCUMENT_DELETED}
														/>
														<EdiDocModal  
																	buttonText={messageResources.BUTTON.AMEND}
																	docuToEdit={rowData}
																	stuId={studentId}
																	amend={true}
																	header={messageResources.MODAL.EDIT_DOC} 
																	text={messageResources.MODAL.DEL_DOC_TEXT}
														/>
													</> 
												
                                    }, 
    ];


	const columnsForCarrees = [
		{ title: "Description",  field: "description" },
        { title: "Type",  field: "type" }, 
		{ title: "Year",  field: "year" },
		{ title: "Upload Date", field: "createdAt", type: 'date', dateSetting: { format: 'dd MMMM yyyy'} },
        { title: "Google Link",  render:rowData=> <a target="_blank" href={rowData.url_link}> CLICK TO OPEN </a> }, 
        { title: "Actions",  render:rowData=>   
                                                studentInfo?.isActive && !roles?.includes(6) && 
													<> 
														<InfoModal  
																	buttonText={messageResources.BUTTON.DELETE}
																	stuId={studentId}
																	toDeleteId={rowData.idDoc}
																	methodType="deleteDoc"
																	header={messageResources.MODAL.DEL_DOC} 
																	text={messageResources.MODAL.DEL_DOC_TEXT}
																	toastText={messageResources.TOASTY.DOCUMENT_DELETED}
														/>
														<EdiDocModal  
																	buttonText={messageResources.BUTTON.AMEND}
																	docuToEdit={rowData}
																	stuId={studentId}
																	amend={true}
																	career={true}
																	header={messageResources.MODAL.EDIT_DOC} 
																	text={messageResources.MODAL.DEL_DOC_TEXT}
														/>
													</> 
												
                                    }, 
    ];


	const { 
		data: archived,
		isLoading,
		isSuccess 
	} = useGetStudentsArchivedQuery({ refetchOnMountOrArgChange: false })

	
	
	const {studentId} = useParams();
	const notis = useSelector(selectAllNotifications)
	const [notiToTaxiId,setNotiToTaxiId] = useState()
	const [notiToMedicalId,setNotiToMedicalId] = useState()
	const [notiToNinId,setNotiToNinId] = useState()
	let addEhRef = useRef()
	const { roles } = useAuth()

	// console.log(studentId)
	// const {data: staffs} = useOpenPdfStuQuery(studentId)

	useEffect(() => {       
        window.scrollTo(0, 0);

		notis.length > 0 && notis.map((notif) => {
			
			if(notif.studentId._id == studentId && !notif.isProcessed && (notif.type == 3 || notif.type == 6 || notif.type == 7)){
				
				if(notif.type == 3){
					setNotiToTaxiId(notif._id)
				}else if(notif.type == 7){
					setNotiToMedicalId(notif._id)
				}else if(notif.type == 6){
					setNotiToNinId(notif._id)
				}
			}
		})	

    },[])
	
	
	let studentInfo = {};

	// TO GUARANTEE THE CONSISTENCY OF THE DATA NETWEEN DIFFERENT USERS
	// WE CANNOT USE useSelector BECAUSE MY STATE IS NOT CHANGED WHEN OTHER 
	// USER EDITS.
	// WE HAVE TO USE THE rtk QUERY DIRECTLY WITH refetchOnMountOrArgChange: true
	// SOO WE RETRIEVE THE INFO FROM THE BACKEND

	const studentFormState = useSelector((state) => selectStudentById(state, studentId))
	
	// const  studentFormState = useGetStudentByIdQuery(studentId, {
	// 			refetchOnMountOrArgChange: true
	// 	});
	

	let showZip = false
	if(studentFormState?.bursary?.zipcard) showZip = true
	
	// ENTERING FROM ARCHIVE
	if(!studentFormState){

		if(isSuccess){
			const {ids, entities} = archived
			studentInfo = entities[studentId]
		}
		
	}else{
		studentInfo = studentFormState
	}

	const today = new Date()
	let showExpired = false
	if(new Date(studentInfo?.passport_expiry).getTime() < today.getTime()){
		showExpired = true
	}
	
	let returner = false;
	let programToCopy 
	
	if(studentInfo?.programmes?.length > 0){
		studentInfo?.programmes?.map(prog => {
			
			if((isFirstHalfOfYear() 
					&& prog.academicYear == currentAcademicYear()
					&& !prog.isActive)
					||
					(!isFirstHalfOfYear() 
					&& prog.academicYear == previousAcademicYear()
					&& !prog.isActive)){
			// today is in the FIRST half of the academic year and 
			// student has a program marked for this year	
			// OR		
			// today is in the SECOND half of the academic year and 
			// student has a program marked for the PREVIOUS year
				returner = true
				programToCopy = prog
			
			}
		})
	}


	let buttons = ""
	if(roles?.includes(2) || roles?.includes(3) || roles?.includes(5)){

		if(studentInfo?.isActive){

			buttons = 	<div className="profile-userbuttons fueraButton">
							<Link to={`addProgramme/${studentId}/add`} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
								{messageResources.BUTTON.ADD_PROGRAMME}
							</Link>
						</div>

			if(returner){

				buttons = 	<div className="profile-userbuttons fueraButton">
								<Link className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary"
										to={`addProgramme/${studentId}/add`} 
										state={{programmeTo: programToCopy }}
								>
									{messageResources.BUTTON.ADD_RETURNER}
								</Link>
							</div>


			}
		}
												
		
		

	}

	//console.log("RETURNER?????")
	//console.log(returner)

	let textSerious = "ATTENTION: this student has serious "
	let messageContent = false;
	if(studentInfo?.medical?.condition?.length > 0){
		studentInfo.medical.condition.map(cond => {
			if(cond.label.includes("Serious")){
				messageContent = true
				textSerious += `${cond.label.substring(0, cond.label.indexOf('(')).toLowerCase()}, `
			}
		})
		
		if(textSerious.trim().endsWith(',')) { textSerious = textSerious.slice(0, -2)}
		textSerious += " . Click on the Medical tab for more information"
		
	}



	// WHEN EDITING EHCP OR PORGRAMM PARTS WE NEED TO DISPLAY LAYERS
	var ehcpButtonDiv = document.getElementById("fuera")
	if(ehcpButtonDiv) ehcpButtonDiv.style.display = 'flex'

	var academicDiv = document.getElementById("fueraAca")
	if(academicDiv) academicDiv.style.display = 'flex'

	var assessDiv = document.getElementById("fueraAssess")
	if(assessDiv) assessDiv.style.display = 'flex'



	// HIDE AND SHOW OUTLETS WITHIN DIVS 
	function hideDivs(que){
		//console.log(que)

		// if we click in the ehcp tab     pro" id="programmeDiv">
		if(que == "ehcpTab"){  
			// was programmeDiv outlet ever present?
			if(document.getElementById("programmeDiv")){
				// if so we remove the wrong outlet opened within this actual tab's content
				var progrDivivInEhcp = document.getElementById("uncontrolled-tab-example-tabpane-ehcpTab").getElementsByClassName("pro")[0]
				//console.log(progrDivivInEhcp)
				if(progrDivivInEhcp){progrDivivInEhcp.style.display = 'none'}
				//console.log(targetDiv.style.display)

			}

			if(document.getElementById("assessmentDiv")){
				
				var assessDivInAssessment = document.getElementById("uncontrolled-tab-example-tabpane-ehcpTab").getElementsByClassName("assess")[0]
				//console.log(progrDivivInEhcp)
				if(assessDivInAssessment){assessDivInAssessment.style.display = 'none'}
				//console.log(targetDiv.style.display)

			}
			
		}else if(que == "academicTab"){
			
			if(document.getElementById("ehcpDiv")){ 

				var ehcpDivInDocs = document.getElementById("uncontrolled-tab-example-tabpane-academicTab").getElementsByClassName("eh")[0]
				//console.log(ehcpDivInDocs)
				if(ehcpDivInDocs){ehcpDivInDocs.style.display = 'none'}
				var ehcpDivInDocs2 = document.getElementById("uncontrolled-tab-example-tabpane-academicTab").getElementsByClassName("eh")[1]
				//console.log(ehcpDivInDocs2)
				if(ehcpDivInDocs2){ehcpDivInDocs2.style.display = 'none'}
			}

			if(document.getElementById("assessmentDiv")){
				
				var assessDivInAssessment = document.getElementById("uncontrolled-tab-example-tabpane-academicTab").getElementsByClassName("assess")[0]
				//console.log(progrDivivInEhcp)
				if(assessDivInAssessment){assessDivInAssessment.style.display = 'none'}
				//console.log(targetDiv.style.display)
				var assessDivInAssessment2 = document.getElementById("uncontrolled-tab-example-tabpane-academicTab").getElementsByClassName("assess")[1]
				//console.log(progrDivivInEhcp)
				if(assessDivInAssessment2){assessDivInAssessment2.style.display = 'none'}
				//console.log(targetDiv.style.display)


			}

		}else if(que == "assessmentTab"){
			
			if(document.getElementById("ehcpDiv")){ 

				var ehcpDivInDocs = document.getElementById("uncontrolled-tab-example-tabpane-assessmentTab").getElementsByClassName("eh")[0]
				//console.log(ehcpDivInDocs)
				if(ehcpDivInDocs){ehcpDivInDocs.style.display = 'none'}
				var ehcpDivInDocs2 = document.getElementById("uncontrolled-tab-example-tabpane-assessmentTab").getElementsByClassName("eh")[1]
				//console.log(ehcpDivInDocs2)
				if(ehcpDivInDocs2){ehcpDivInDocs2.style.display = 'none'}
			}

			if(document.getElementById("programmeDiv")){
				// if so we remove the wrong outlet opened within this actual tab's content
				var progrDivivInEhcp = document.getElementById("uncontrolled-tab-example-tabpane-assessmentTab").getElementsByClassName("pro")[0]
				//console.log(progrDivivInEhcp)
				if(progrDivivInEhcp){progrDivivInEhcp.style.display = 'none'}
				//console.log(targetDiv.style.display)
				var progrDivivInEhcp2 = document.getElementById("uncontrolled-tab-example-tabpane-assessmentTab").getElementsByClassName("pro")[1]
				//console.log(progrDivivInEhcp)
				if(progrDivivInEhcp2){progrDivivInEhcp2.style.display = 'none'}
				//console.log(targetDiv.style.display)

			}

		}
	
	}
	
    return (
    
		<>
			<Breadcrumb pageTitle={`${studentInfo?.name} ${studentInfo?.surname} ${messageResources.OPS_TITLES.PROFILE} `}/>
			<div className="row"> 
			
				<Tabs   defaultActiveKey="pending" 
						id="uncontrolled-tab-example" 
						className="mb-3"
						onSelect={(k) => hideDivs(k)}
					>
					
					<Tab eventKey="pending" title={messageResources.STUDENT.PERSONAL_DATA}>
						{/* <!-- BEGIN PROFILE SIDEBAR --> */}
						{/* <div className="profile-sidebar"> */}

						
						{/* {roles?.includes(2) && studentInfo?.activeProgramme && <a onClick={handleClose}>I AM TESTING</a> } */}

						{showExpired && <Messages message={messageResources.STUDENT.PASS_EXPIRED} type="error" />} 

						{messageContent && <Messages message={textSerious} type="error" />}

						{studentInfo?.taxi && !studentInfo?.taxi?.isIndependent && <Messages message={messageResources.STUDENT.NON_INDEPEN} type="alert" />}

						<div className="row"> 
							
							<PdStudentCard studentInfo={studentInfo} />

							<AboutStudentCard studentInfo={studentInfo} notificationId={notiToNinId}/>
							
							<StreetLeafMap slots="col-lg-6 col-md-12" address={studentInfo?.address} keyMap={Math.random()} showHeader={true}/>

							{/* <MapBox slots="col-lg-6 col-md-12" address={studentInfo.address} /> */}
							
						</div>
					</Tab>
					
					<Tab eventKey="nokinsTab" title={messageResources.OPS_TITLES.NOK}>
								
						{studentInfo?.kins && studentInfo?.kins.length > 0 
					
						?
							<>
								<div className="row"> 
									{studentInfo?.kins.map((nokin) => (
									
										<NokCard nokin={nokin} 
												entityId={studentInfo?._id}
												isStuActive={studentInfo?.isActive}
										/>
									
									))}
								</div>
							</>
						:
							<>
								<Messages message={messageResources.BANNER.NO_NOK} type="alert" />
							</>
							
						}

						{studentInfo?.isActive && !roles?.includes(6) && 
							<EditNokModal
								buttonText={messageResources.BUTTON.ADD_NOK}
								stuId={studentInfo?._id}
								amend={false}
								header={messageResources.MODAL.ADD_NOK} 
							/>
						}

						{/* <div className="profile-userbuttons">
							<Link to={`addKin/${studentInfo._id}`} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
								{messageResources.BUTTON.ADD_KIN}
							</Link>
						</div> */}
						
					</Tab>

					<Tab eventKey="ehcpTab" title={messageResources.STUDENT.EHCP}>

						{/* <!-- BEGIN PROFILE CONTENT --> */}
						<div id="biography">
							<Outlet />
							<div className="row" id="fuera" >
								{studentInfo?.ehcp ? 
								<><div className="row">
									
									
									<MonoDataCard 	slots="col-lg-2 col-md-12"
												message={messageResources.STUDENT.EHCP_DATE}
												isDate={true}
												data={studentInfo?.ehcp.date}
											/>
								
									<MonoDataCard 	slots="col-lg-2 col-md-12"
												message={messageResources.STUDENT.ANNUAL_REVIEW}
												isDate={true}
												data={studentInfo?.ehcp.last_review}
											/>

									{studentInfo?.ehcp.next_review && 
										<MonoDataCard 	slots="col-lg-2 col-md-12"
													message={messageResources.STUDENT.NEXT_REVIEW}
													isDate={true}
													data={studentInfo?.ehcp.next_review}
												/>
									}

									<MonoDataCard 	slots="col-lg-2 col-md-12"
												message={messageResources.STUDENT.EHCP_DOC}
												isLink={true}
												data={studentInfo?.doc_link}
											/>
									
									{studentInfo?.ehcp.year_one && 
										<MonoDataCard 	slots="col-lg-2 col-md-12"
												message={messageResources.STUDENT.REVIEW_ONE}
												isLink={true}
												data={studentInfo?.ehcp.year_one}
											/>
									}
									
									{studentInfo?.ehcp.year_two && 
										<MonoDataCard 	slots="col-lg-2 col-md-12"
												message={messageResources.STUDENT.REVIEW_TWO}
												isLink={true}
												data={studentInfo?.ehcp.year_two}
											/>
									}
									
									{studentInfo?.ehcp.year_three && 
										<MonoDataCard 	slots="col-lg-2 col-md-12"
												message={messageResources.STUDENT.REVIEW_THREE}
												isLink={true}
												data={studentInfo?.ehcp.year_three}
											/>
									}

									{studentInfo?.ehcp.year_four && 
										<MonoDataCard 	slots="col-lg-2 col-md-12"
												message={messageResources.STUDENT.REVIEW_FOUR}
												isLink={true}
												data={studentInfo?.ehcp.year_four}
											/>
									}
								
				
									{/* <div className="col-lg-2 col-md-12">
										<div className="card card-topline-aqua">
											<div className="card-body no-padding height-9">
												<div className="profile-usertitle">
													<div className="profile-usertitle-name">{messageResources.STUDENT.EHCP_LAST} </div>
												</div>
												<ul className="list-group list-group-unbordered">
													<li className='centerText'>
														<a>{studentInfo.ehcp.isApproved ? "YES" : "NO"}</a>
													</li>
												</ul>
											</div>
										</div>
									</div> */}
								</div>
								<hr/>
								<div className="row">
									
										<MonoDataCard 	slots="col-lg-12 col-md-12"
											message={messageResources.STUDENT.LD}
											isList={true}
											data={studentInfo?.ehcp.ld}
										/>

										{(roles?.includes(2) || roles?.includes(4) || roles?.includes(5)) && studentInfo?.isActive &&
											<div className="profile-userbuttons">
												<Link to={`addEHCP/${studentId}/edit`}  ref={addEhRef} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
													{messageResources.BUTTON.EDIT_EHCP}
												</Link>
											</div>
										}

								
								</div>
								</>
								: 
								<>
									<Outlet />
									<div id="fuera">
										<Messages message={messageResources.BANNER.NO_EHCP} type="alert" />
										
										{(roles?.includes(2) || roles?.includes(4) || roles?.includes(5)) && studentInfo?.isActive && 
											
											<div className="profile-userbuttons fueraButton">
												<Link to={`addEHCP/${studentId}/add`} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
													{messageResources.BUTTON.ADD_EHCP}
												</Link>
											</div>
											
										}
									</div>
								</>
								
								}

							</div>
						</div>
						{/* <!-- END PROFILE CONTENT --> */}
					</Tab>   

					<Tab eventKey="medicalTab" title={messageResources.STUDENT.MEDICAL}>
					
						{studentInfo?.medical ? 
									
							<div id="biography">
								<div class="row bigger">
									
									<div class="col-md-6 col-6 b-r bigger"> 
										<strong>{messageResources.MEDICAL.CONDITION}</strong>
										<br/>
										<p class="m-t-30"> { printListComa(studentInfo?.medical?.condition) } </p>
									</div>	
									{/* <div class="col-md-6 col-6 b-r bigger"> 
										<strong>{messageResources.MEDICAL.DIETARY}</strong>
										<br/>
										<p class="m-t-30"> { printListComa(studentInfo?.medical?.dietary) } </p>
									</div> */}

									{/* <Notebook
											cuanto="cuanto49"
											agujeros="0"
											title={messageResources.MEDICAL.CONDITION}
											data={ printListComa(studentInfo?.medical?.condition) }
									/>
									<Notebook
											cuanto="cuanto49"
											agujeros="0"
											title={messageResources.MEDICAL.DIETARY}
											data={ printListComa(studentInfo?.medical?.dietary) }
									/> */}
									
								</div>	
								<hr/>
								<div class="row">
									
									{/* <Notebook
											cuanto="cuanto32"
											agujeros="3"
											title={messageResources.MEDICAL.MEDICATION}
											data={studentInfo?.medical?.medication}
									/>
									<Notebook
											cuanto="cuanto32"
											agujeros="3"
											title={messageResources.MEDICAL.ADMNISTRATION}
											data={studentInfo?.medical?.assistance}
									/>
									<Notebook
											cuanto="cuanto32"
											agujeros="3"
											title={messageResources.COMMON.COMMENTS}
											data={studentInfo?.medical?.notes}
									/> */}

									<div class="col-md-4 col-6 b-r bigger keepJump"> 
										<strong>{messageResources.MEDICAL.ADMNISTRATION}</strong>
										<br/>
										<p class="text-muted">
											{studentInfo?.medical?.medication}
										</p>
									</div>
									<div class="col-md-4 col-6 b-r bigger keepJump"> 
										<strong>{messageResources.MEDICAL.DIETARY}</strong>
										<br/>
										<p class="text-muted">
											{studentInfo?.medical?.assistance}
										</p>
									</div>
									<div class="col-md-4 col-6 b-r bigger keepJump"> 
										<strong>{messageResources.COMMON.COMMENTS}</strong>
										<br/>
										<p class="text-muted">
											{studentInfo?.medical?.notes}
										</p>
									</div>
								</div>
									
								
								{roles && (roles?.includes(2) || roles?.includes(3) || roles?.includes(5)) && studentInfo?.isActive && 
								
									<EditMedicalModal
										buttonText={messageResources.MODAL.AMEND_MEDICAL}
										stuId={studentId}
										medicalToEdit={studentInfo?.medical}
										header={messageResources.MODAL.AMEND_MEDICAL} 
										amend={true}
									/>
								}
							</div>
							

							:

							<>
								<Messages message={messageResources.BANNER.NO_MEDICAL} type="alert" />

								{roles && (roles?.includes(2) || roles?.includes(3) || roles?.includes(5)) && studentInfo?.isActive &&

									<div className="profile-userbuttons">

										<EditMedicalModal
											buttonText={messageResources.MODAL.ADD_MEDICAL}
											stuId={studentId}
											medicalToEdit={studentInfo?.medical}
											header={messageResources.MODAL.ADD_MEDICAL} 
											amend={false}
										/>
										
										{notiToMedicalId &&

											<ProcessNotiModal
												//linkTo={`/students/delTaxi/${entityId}`} 
												buttonText={messageResources.MODAL.NO_MEDICAL.toUpperCase()}
												stuId={studentId}
												notificationId={notiToMedicalId}
												//methodType="put"
												toastText={messageResources.TOASTY.NOTI_PROCESS}
												header={messageResources.MODAL.NO_MEDICAL} 
												text={messageResources.MODAL.NO_MEDICAL_TEXT}
											/>

										}
									</div>
								}	
							</>

						}
						
					</Tab>
				
					<Tab eventKey="docsTab" title={messageResources.OPS_TITLES.DOCUMENTS}>

						{studentInfo?.isActive && !roles?.includes(6) && 
							<div className="profile-userbuttons">
								<EdiDocModal  
									buttonText={messageResources.BUTTON.ADD_DOC}
									stuId={studentId}
									amend={false}
									header={messageResources.MODAL.ADD_DOC} 
								/>
							</div>	
						}

						{/* DOC LIST BUILT IN REDUX CALL */}
						{studentInfo?.docListToShow && studentInfo?.docListToShow.length > 0 
							?
							
							<List   titleForTable={`${messageResources.COMMON.TOTAL} ${messageResources.COMMON.DOCUMENTS} ${studentInfo?.docListToShow?.length}`}
									dataForTable={JSON.parse(JSON.stringify(studentInfo?.docListToShow))} 
									columnsForTable={columnsForStuDocuments}
									filter={false} 
									pageSize={studentInfo?.docListToShow?.length}
									//pageSize={studentInfo?.docListToShow?.length <= 10 ? studentInfo?.docListToShow?.length : 10}
							/>
						
							:
							<Messages message={messageResources.BANNER.NO_DOCS} type="alert" />
						}

						

						{/* <div className="profile-userbuttons">
							<Link to={`addDocs/${studentId}`} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
								{messageResources.BUTTON.ADD_DOC}
							</Link>
						</div> */}

					</Tab>

					<Tab eventKey="supportTab" title={messageResources.OPS_TITLES.SUPPORT}>


						{studentInfo?.supWorker && studentInfo?.supWorker.length > 0 
						
						?
							
							<div className="row"> 
								{studentInfo?.supWorker.map((worker) => (

									<SupportCard
										worker={worker}
										entityId={studentId}
										number={studentInfo?.supWorker.length}
										isStuActive={studentInfo?.isActive}
									/>
								))}
							</div>
								
						:

							<>
								<Messages message={messageResources.BANNER.NO_SUPPORT} type="alert" />
								
							</>

						}

						{(roles?.includes(2) || roles?.includes(3) || roles?.includes(5)) && studentInfo?.isActive &&
							
							<EditSupportModal
								buttonText={messageResources.MODAL.ADD_SUPPORT}
								header={messageResources.MODAL.ADD_SUPPORT} 
								stuId={studentId}
								amend={false}
							/>

						}
						
					</Tab>

					<Tab eventKey="taxiTab" title={messageResources.OPS_TITLES.TAXI}>

						{showZip && <Messages message={messageResources.STUDENT.HAS_ZIP} type="success" />} 
					
						{studentInfo?.taxi 
						
							?

							<TaxiCard
								taxi={studentInfo?.taxi}
								entityId={studentId}
								isStuActive={studentInfo?.isActive}
							/>

							:

							<>
								<Messages message={messageResources.BANNER.NO_TAXI} type="alert" />

								{roles && (roles?.includes(2) || roles?.includes(3) || roles?.includes(5)) && studentInfo?.isActive &&

									<div className="profile-userbuttons">
										<EditTaxiModal
											buttonText={messageResources.MODAL.ADD_TAXI}
											stuId={studentId}
											header={messageResources.MODAL.ADD_TAXI} 
											amend={false}
										/>
										
										{notiToTaxiId &&

											<ProcessNotiModal
												buttonText={messageResources.MODAL.NO_TAXI.toUpperCase()}
												notificationId={notiToTaxiId}
												toastText={messageResources.TOASTY.NOTI_PROCESS}
												header={messageResources.MODAL.NO_TAXI} 
												text={messageResources.MODAL.NO_TAXI_TEXT}
											/>

										}
									</div>
								}	
							</>

						}

					</Tab>

					<Tab eventKey="careerTab" title={messageResources.STUDENT.CAREER}>
						<div className="profile-content">
							
							<div className="row" id="fueraCareers" >
								{ studentInfo?.careers 
								
								? 
									<>
										<MonoDataCard 	slots="col-lg-2 col-md-12"
											message={messageResources.CAREERS.CV}
											isLink={true}
											data={studentInfo?.careers.cv_link}
										/>
										<MonoDataCard 	slots="col-lg-2 col-md-12"
											message={messageResources.CAREERS.VOCATIONAL}
											isLink={true}
											data={studentInfo?.careers.voc_link}
										/>
										<MonoDataCard 	slots="col-lg-2 col-md-12"
											message={messageResources.CAREERS.PLAN}
											isLink={true}
											data={studentInfo?.careers.plan_link}
										/>
										
										{studentInfo?.careers?.notes && <MonoDataCard 	slots="col-lg-6 col-md-12"
											message={messageResources.COMMON.NOTES}
											data={studentInfo?.careers.notes}
										/>}

										{(roles?.includes(2) || roles?.includes(3) || roles?.includes(5)) && !roles?.includes(6) && studentInfo?.isActive &&
											<div className="profile-userbuttons">
												<EditCareersModal
													buttonText={messageResources.MODAL.AMEND_CAREERS}
													stuId={studentId}
													studentInfo={studentInfo}
													header={messageResources.MODAL.AMEND_CAREERS} 
													amend={true}
												/>

												<EdiDocModal  
													buttonText={messageResources.BUTTON.ADD_DOC_CARR}
													stuId={studentId}
													amend={false}
													career={true}
													header={messageResources.MODAL.ADD_DOC} 
												/>
											</div>
										}



										{/* DOC LIST BUILT IN REDUX CALL */}
										{studentInfo?.carrersDocsToShow && studentInfo?.carrersDocsToShow.length > 0 
											?
											
											<List   titleForTable={messageResources.CAREERS.DOCS}
													dataForTable={JSON.parse(JSON.stringify(studentInfo?.carrersDocsToShow))} 
													columnsForTable={columnsForCarrees}
													filter={false} 
													pageSize={studentInfo?.carrersDocsToShow?.length}
													//pageSize={studentInfo?.docListToShow?.length <= 10 ? studentInfo?.docListToShow?.length : 10}
											/>
										
											:
											<Messages message={messageResources.BANNER.NO_DOCS_SECTION} type="alert" />
										}

										
										
									</>

									
								: 
									<>
										<Messages message={messageResources.BANNER.NO_CAREERS} type="alert" />
										
										{(roles?.includes(2) || roles?.includes(3) || roles?.includes(5)) && studentInfo?.isActive &&
											<div className="profile-userbuttons">
												<EditCareersModal
													buttonText={messageResources.MODAL.ADD_CAREERS}
													stuId={studentId}
													header={messageResources.MODAL.ADD_CAREERS} 
													amend={false}
												/>
											</div>
										}
									</>	
									
								}			
							</div>	
						</div>

					
					</Tab>

					<Tab eventKey="assessmentTab" title={messageResources.STUDENT.ASSESSMENT}>

						<div className="profile-content">
							<Outlet />
							<div className="row" id="fueraAssess" >
								{ studentInfo?.assessment
								
								?
									<>
									
										<ProgrammeCardThird assessment={studentInfo?.assessment}/>

										{studentInfo?.assessment?.engResults?.value &&

											<AssessmentCardEng assessment={studentInfo?.assessment} />
											
										}

										{studentInfo?.assessment?.mathsResults?.value &&
											
											<AssessmentCardMaths assessment={studentInfo?.assessment} />
										}
 
										

										{(roles?.includes(2) || roles?.includes(4) || roles?.includes(5)) && studentInfo?.isActive &&
											<div className="profile-userbuttons">
												<Link to={`addAssessment/${studentId}/edit`}  className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
													{messageResources.BUTTON.EDIT_ASSESSMENT}
												</Link>
											</div>
										}
									</>
								:
									<>
										<Outlet />
										<div id="fueraAssess">
											<Messages message={messageResources.BANNER.NO_ASSESSMENT} type="alert" />

											{(roles?.includes(2) || roles?.includes(4) || roles?.includes(5)) && studentInfo?.isActive && 
											
												<div className="profile-userbuttons fueraButton">
													<Link to={`addAssessment/${studentId}/add`} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
														{messageResources.BUTTON.ADD_ASSESSMENT}
													</Link>
												</div>
												
											}

										</div>

									</>
								}
							</div>

						</div>
					</Tab>

					<Tab eventKey="bursaryTab" title={messageResources.STUDENT.BURSARY}>

						{ studentInfo?.bursary 

						?

							<div id="biography">
								<div class="row bigger">
									<div class="col-md-5 col-6 b-r bigger" style={{display: 'flex'}}> 
										<strong>{messageResources.BURSARY.MEALS}:</strong>
										<p style={{fontSize: '19px', marginLeft: '10px'}}> { studentInfo?.bursary?.meals ? "YES" : "NO" } </p>
										{/* <p style={{float: 'right', fontSize: '19px', marginRight: '5px'}}> { studentInfo?.bursary?.meals ? "YES" : "NO" } </p> */}
									</div>	
									<div class="col-md-5 col-6 b-r bigger" style={{display: 'flex'}}> 
										<strong>{messageResources.BURSARY.ZIPCARD}:</strong>
										<p style={{fontSize: '19px', marginLeft: '10px'}}> { studentInfo?.bursary?.zipcard ? "YES" : "NO" } </p>
									</div>
								</div>	
								<hr/>
								<div class="row bigger">
									<div class="col-md-5 col-6 b-r bigger" style={{display: 'flex'}}> 
										<strong>{messageResources.BURSARY.DISCRETIONARY}:</strong>
										<p style={{fontSize: '19px', marginLeft: '10px'}}> { studentInfo?.bursary?.discretionary ? "YES" : "NO" } </p>
									</div>
									{studentInfo?.bursary?.discretionary_link &&
										<div class="col-md-5 col-6 b-r bigger" style={{display: 'flex'}}> 
											<strong>{messageResources.DOCUMENTS.DISCRETIONARY_PROOF}:</strong>
											<p>
												<a style={{fontSize: '18px', marginLeft: '10px'}} target="_blank" href={studentInfo?.bursary?.discretionary_link}> {messageResources.COMMON.OPEN} </a>
											</p>
										</div>
									}
								</div>	
								<hr/>
								<div class="row bigger">
									<div class="col-md-5 col-6 b-r bigger" style={{display: 'flex'}}> 
										<strong>{messageResources.BURSARY.VULNERABLE}:</strong>
										<p style={{fontSize: '19px', marginLeft: '10px'}}> { studentInfo?.bursary?.vulnerable ? "YES" : "NO" } </p>
									</div>
									{studentInfo?.bursary?.vulnerable_link &&
										<div class="col-md-5 col-6 b-r bigger" style={{display: 'flex'}}> 
											<strong>{messageResources.DOCUMENTS.VULNERABLE_PROOF}:</strong>
											<p >
												<a style={{fontSize: '18px', marginLeft: '10px'}} target="_blank" href={studentInfo?.bursary?.vulnerable_link}> {messageResources.COMMON.OPEN} </a>
											</p>
										</div>
									}
								</div>	
								

								{roles && (roles?.includes(2) || roles?.includes(3) || roles?.includes(5)) && studentInfo?.isActive && 
								
									<EditBursaryModal
										buttonText={messageResources.MODAL.AMEND_BURSARY}
										stuId={studentId}
										bursaryToEdit={studentInfo?.bursary}
										header={messageResources.MODAL.AMEND_MEDICAL} 
										amend={true}
									/>
								}
							</div>
						:
							<>
								<Messages message={messageResources.BANNER.NO_BURSARY} type="alert" />
								
								{(roles?.includes(2) || roles?.includes(3) || roles?.includes(5)) && studentInfo?.isActive &&
									<div className="profile-userbuttons">
										<EditBursaryModal
											buttonText={messageResources.MODAL.ADD_BURSARY}
											stuId={studentId}
											header={messageResources.MODAL.ADD_BURSARY} 
											amend={false}
										/>
									</div>
								}
							</>	

						}

					</Tab>

					<Tab eventKey="academicTab" title={messageResources.STUDENT.PROGRAMME}>

						{/* <!-- BEGIN PROFILE CONTENT --> */}
						<div className="profile-content">
							<Outlet />
							<div className="row" id="fueraAca" >
								{ studentInfo?.activeProgramme 
								
								? 
									<>
										<MonoDataCard 	slots="col-lg-12"
											message={`${studentInfo?.activeProgramme?.programme?.label} - ${studentInfo?.activeProgramme?.speciality?.label}`}
											isTitle={true}
										/>
										<ProgrammeCard programme={studentInfo?.activeProgramme}/>
										<ProgrammeCardSecond programme={studentInfo?.activeProgramme}/>
										<ProgrammeCardFourth programme={studentInfo?.activeProgramme}/>

										{(roles?.includes(2) || roles?.includes(4) || roles?.includes(5)) && studentInfo?.isActive &&
											<div className="profile-userbuttons">
												<Link to={`addProgramme/${studentId}/edit`}  ref={addEhRef} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
													{messageResources.BUTTON.EDIT_PROGRAMME}
												</Link>
											</div>
										}

										{studentInfo?.activeProgramme?.suppActivities && studentInfo?.activeProgramme?.suppActivities.length > 0 &&
											<Subjects subjects={studentInfo?.activeProgramme?.suppActivities} 
													progId={studentInfo?.activeProgramme?._id}
													stuId={studentId}
													tableTitle={`${messageResources.PROGRAMME.SUP_ACTIS}`}
												/>
										}

										{studentInfo?.activeProgramme?.eepActivities && studentInfo?.activeProgramme?.eepActivities.length > 0 &&
											<Subjects subjects={studentInfo?.activeProgramme?.eepActivities} 
													progId={studentInfo?.activeProgramme?._id}
													stuId={studentId}
													tableTitle={messageResources.PROGRAMME.PLAN_EEP}
												/>
										}

										{studentInfo?.activeProgramme?.cgQualifications && studentInfo?.activeProgramme?.cgQualifications.length > 0 &&
											<Subjects subjects={studentInfo?.activeProgramme?.cgQualifications} 
													progId={studentInfo?.activeProgramme?._id}
													stuId={studentId}
													tableTitle={messageResources.PROGRAMME.CyG_QUALI}
												/>
										}
									</>
								: 
									<>
										<Outlet />
										<div id="fueraAca">
											<Messages message={messageResources.BANNER.NO_PROGRAMME} type="alert" />

											{buttons}
											
										</div>
									</>
								}			
							</div>	
						</div>
					</Tab>					
					
				</Tabs>
				
			</div>  
		</>

    )
}

export default memo(StudentProfile)
